<template>
  <v-apex-chart
    type="pie"
    :series="chart.series"
    :options="chart.options"
    card_height="230"
    height="100%"
    tile
    :toolbar="false"
  ></v-apex-chart>
</template>

<script>
export default {
  name: "sales",
  components: { VApexChart: () => import("@/components/apexChart") },
  data: () => ({
    dataList: [
      [50, 25, 10, 5],
      [25, 20, 15, 10],
      [100, 90, 80, 70],
      [4, 3.5, 3, 2.5],
      [200, 100, 50, 25]
    ],
    currentSeries: [50, 25, 10, 5],
    event: null
  }),
  mounted() {
    this.currentSeries = [100, 0, 0, 0];
    setTimeout(() => {
      this.currentSeries = [50, 25, 10, 5];
    }, 1000);
  },
  computed: {
    chart() {
      let options = {},
        series = this.currentSeries;
      //     labels = ["1st Qtr", "2nd Qtr", "3rd Qtr", "4th Qtr"];
      // ,
      //   labels
      options = {
        chart: {
          type: "pie"
        },
        legend: false,
        dataLabels: {
          enabled: false
          // formatter(val, opts) {
          //   const name = opts.w.globals.labels[opts.seriesIndex];
          //   [name, val.toFixed(1) + "%"]
          //   return null;
          // }
        },
        tooltip: {
          enabled: false
        },
        markers: {
          enabled: false,
          size: 0,
          style: "hollow"
        }
      };
      return { options, series };
    }
  }
};
</script>

<style scoped></style>
